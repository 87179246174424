import { CommonActions, useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { images } from "@src/theme";
import { Button } from "components/button";
import React from "react";
import { Image, Linking, Text, View } from "react-native";
import useStyles from "./styles.css";

const SignUpVerificationFailed = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  const onContactUs = () => {
    Linking.openURL("https://fortune8.online/contact-us");
  };

  const onPress = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: "SignInOption" },
        ],
      })
    );
  };

  return (
    <View style={styles.viewEmail}>
      <Image
        resizeMode="contain"
        source={images["text-verification-failed"]}
        style={styles.i_failed}
      />
      <View style={{ height: "5%"}} />
      <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
        <Image
          resizeMode="stretch"
          source={images["close_circle"]}
          style={{width: "12%", height: "70%", marginRight: "2%"}}
        />
        <Text style={[styles.t_failed, {fontSize: baseWidth * 0.017}]}>
          We were unable to verify your email address.{"\n"}
          Please try again or use a different email address{"\n"}
          to register an account.{"\n\n"}
          Having trouble signing up?{"  "}
          <Text style={styles.t_failed_bold}
            suppressHighlighting onPress={onContactUs}>Contact Us
          </Text>
        </Text>
      </View>
      <Button
        label="BACK"
        onPress={onPress}
        style={styles.btn_login}
        width={baseWidth * 0.12}
        height={baseWidth * 0.05}
        labelStyle={{fontSize: baseWidth * 0.012}}
      />
    </View>
  );
};

export default SignUpVerificationFailed;
