import { useRoute } from "@react-navigation/native";
import Loading from "components/loading/Loading";
import React from "react";
import colors from "theme/colors";

const DailyBonus = React.lazy(() => import("screens/daily-bonus"));
const DragonRoller = React.lazy(() => import("./dragon-roller"));
const HighRoller = React.lazy(() => import("./high-roller"));
const HotDeals = React.lazy(() => import("./hot-deals"));
const TGIF = React.lazy(() => import("./tgif"));
const StarterPack = React.lazy(() => import("./starter-pack"));
const WeeklyBooster = React.lazy(() => import("./weekly-booster"));
const WelcomeBonus = React.lazy(() => import("screens/welcomebonus"));
const Settings = React.lazy(() => import("screens/gamemode/settings"));
const BuyShop = React.lazy(() => import("screens/buyshop"));
const JadeEggShow = React.lazy(() => import("screens/jadeegg/show"));
const HotDealsThumbnails = React.lazy(() => import("screens/popups/thumbnails"));
const TierRewards = React.lazy(() => import("screens/popups/tier-rewards"));
const TierLevelUp = React.lazy(() => import("screens/popups/tier-level-up"));
const GoldCoinEarningNotification = React.lazy(() => import("screens/popups/gold-coin"));
const PremiumPackages = React.lazy(() => import("screens/popups/premium-packages"));

const AnimationPopup = () => {
  const route = useRoute<any>();

  const components = React.useMemo(() => {
    switch (route.name) {
    case "DailyBonus":
      return <DailyBonus />;
    case "DragonRoller":
      return <DragonRoller />;
    case "HighRoller":
      return <HighRoller />;
    case "HotDeals":
      return <HotDeals />;
    case "TGIF":
      return <TGIF />;
    case "StarterPack":
      return <StarterPack />;
    case "WeeklyBooster":
      return <WeeklyBooster />;
    case "WelcomeBonus":
      return <WelcomeBonus />;
    case "Settings":
      return <Settings />;
    case "BuyShop":
      return <BuyShop />;
    case "JadeEggShow":
      return <JadeEggShow />;
    case "HotDealsThumbnails":
      return <HotDealsThumbnails />;
    case "TierRewards":
      return <TierRewards />;
    case "TierLevelUp":
      return <TierLevelUp />;
    case "GoldCoinEarning":
      return <GoldCoinEarningNotification />;
    case "PremiumPackages":
      return <PremiumPackages />;
    // default:
    //   return <BuyShop />;
    }
  }, [route.name]);

  return (
    <React.Suspense fallback={<Loading customStyle={{backgroundColor: colors.translucent3}} isLoading />}>
      {components}
    </React.Suspense>
  );
};

export default AnimationPopup;