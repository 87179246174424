import { images } from "@src/theme";
import useMetrics from "@src/utils/metrics";
import { Loading02 } from "components/loading";
import React from "react";
import { Image, ImageBackground, Text } from "react-native";
import useCacheImage from "utils/cache-helper";
import Package from "../../../package.json";
import useStyles from "./styles.css";

const VERSION = `Version ${Package.version}`;

const SplashBG = ({ style }: any) => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const appIsReady = useCacheImage([images.fortune_logo, images.gw_bg]);

  if(!appIsReady){
    return <Loading02 />;
  }

  return (
    <ImageBackground
      resizeMode="stretch"
      source={images.gw_bg}
      style={[styles.container, style]}
    >
      <Image
        source={images.fortune_logo}
        style={[
          { height: verticalScale(380) },
          styles.i_logo,
        ]}
        resizeMode="stretch"
      />
      <Text style={styles.t_version}>{VERSION}</Text>
    </ImageBackground>
  );
};

export default React.memo(SplashBG);
