import React, { useEffect } from "react";
import { Dimensions } from "react-native";
import Animated, {
  withTiming,
  interpolate,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

const HeightAnimated = ({ children, style, toValue, duration }: any) => {
  const animated = useSharedValue(0);
  const SCREEN_HEIGHT = Dimensions.get("window").height;

  useEffect(() => {
    animated.value = withTiming(toValue, { duration: duration || 250 });
  }, [toValue]);

  const animateStyle = useAnimatedStyle(() => {
    const height = interpolate(animated.value, [0, 1], [0, SCREEN_HEIGHT]);

    return { height };
  }, []);

  return (
    <Animated.View style={[style, animateStyle]}>{children}</Animated.View>
  );
};

export default HeightAnimated;
