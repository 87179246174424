import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "81%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    bottomHeight: {
      width: 100,
      height: "12%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto_Medium",
    },

    // SIGNUP COMPLETED
    v_completed: {
      width: "60%",
      height: "70%",
      alignItems: "center",
      justifyContent: "center",
    },

    t_thankyou: {
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto_Regular",
    },
    t_failed: {
      color: colors.white,
      fontFamily: "Roboto_Regular",
    },
    t_failed_bold: {
      fontFamily: "Roboto_Bold",
    },

    v_congrats: {
      width: "70%",
      height: "24%",
      marginTop: "1%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_success: {
      width: "40%",
      height: "12%",
      marginTop: "7%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_failed: {
      width: "60%",
      height: "14%",
      marginTop: "7%",
      alignItems: "center",
      justifyContent: "center",
    },
    btn_login: {
      width: "21%",
      height: "13%",
      marginLeft: 10,
      marginTop: "4%",
    },

    // ENTER FIELDS COMPONENT STYLE
    viewForgotStyle: {
      width: "40%",
      height: "24%",
      marginTop: "2%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: "100%" },
    viewEmail: {
      flex: 1,
      width: "100%",
      alignItems: "center",
    },
    v_name: {
      width: "90%",
      height: "12%",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1.2%",
      justifyContent: "space-between",
    },

    fn_style: {
      marginBottom: 0,
      borderRadius: 2,
      width: "28%",
      height: "100%",
    },
    ti_email_style: {
      marginBottom: 0,
      borderRadius: 2,
      width: "40%",
      height: "100%",
    },
    v_password: { marginTop: "1%" },

    ti_password: {
      marginBottom: 0,
      borderRadius: 2,
      width: "35%",
      height: "100%",
    },
    ti_referral: {
      marginBottom: 0,
      borderRadius: 2,
      width: "26%",
      height: "100%",
    },
    textAlign: { textAlign: "center" },
    height20: { height: "7%" },
    v_required: {
      width: "18%",
      height: "9%",
      marginTop: "1%",
      borderRadius: 6,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.skyblue,
    },
    t_required: {
      fontFamily: "Roboto_Regular",
      fontSize: 12,
      color: colors.black,
    },
    btnSubmit: {
      height: "100%",
      width: "25%",
      marginLeft: 10,
    },
    imageCloseStyle: { height: "100%", width: "100%" },
    btnClose: {
      width: "4%",
      height: "12%",
      zIndex: 1,
      top: "7%",
      right: "4%",
      position: "absolute",
    },
    v_actions: { width: "90%", height: "17%", marginTop: "4.5%", alignItems: "center"},
    btn_back: { position: "absolute", width: "6%", height: "80%", left: 0, 
      alignItems: "center", justifyContent: "center"},
    i_back: {width: "100%", height: "76%"},
    t_back: {fontFamily: "Roboto_Medium", fontSize: 20, color: "white"},
  },
);

export default useStyles;
