// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import _ from "lodash";
import moment from "moment";
import { getSilverGold, userRewardsUpdate } from "utils/filter-helper";
import { ErrorValue, LoginInput, SessionValue } from "../types";

interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  error: ErrorValue;
  errorMessage: string;
  termsAndCondition: boolean;
  backgroundSound: boolean;
  favAdsFirstShow: boolean;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  session: { token: ""} as SessionValue,
  error: {} as ErrorValue,
  errorMessage: "",
  termsAndCondition: false,
  backgroundSound: false,
  favAdsFirstShow: true,
} as AuthState;

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginGoogleRequest: (state) => {
      state.loading = true;
    },
    loginFBRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
      state.isLoggedIn = true;
      state.backgroundSound = true;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      return {
        ...initialState, 
        termsAndCondition: state.termsAndCondition,
        favAdsFirstShow: state.favAdsFirstShow,
        backgroundSound: false
      };
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateProfile: (state, action) => {
      state.session["firstName"] = action.payload.firstName;
      state.session["lastName"] = action.payload.lastName;
      state.session["dateOfBirth"] = action.payload.dateOfBirth;
      state.session["userDetail"] = action.payload.userDetail;
    },
    updateWallet: (state, action) => {
      state.session["userBalanceList"] = action.payload;
    },
    updateUserRewards: (state, action) => {
      state.session["userRewards"] = userRewardsUpdate(state?.session?.userRewards ?? [], action.payload);
    },
    termsAndCondition: (state, action) => {
      state.termsAndCondition = action.payload;
    },
    backgroundSound: (state, action) => {
      state.backgroundSound = action.payload;
    },
    refreshToken: (state, action) => {
      state.session["token"] = action.payload;
    },
    favAdsFirstShowOff: (state) => {
      state.favAdsFirstShow = false;
    },
  },
});

// Actions
export const authActions = {
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  loginGoogleRequest: createAction(
    `${authSlice.name}/loginGoogleRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginFBRequest: createAction(
    `${authSlice.name}/loginFBRequest`,
    (params: string) => ({
      payload: params,
    })
  ),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,
  logout: authSlice.actions.logout,
  setErrorMessage: authSlice.actions.setErrorMessage,
  updateWallet: authSlice.actions.updateWallet,
  updateUserRewards: authSlice.actions.updateUserRewards,
  updateProfile: authSlice.actions.updateProfile,
  termsAndCondition: authSlice.actions.termsAndCondition,
  backgroundSound: authSlice.actions.backgroundSound,
  resetLoading: authSlice.actions.resetLoading,
  refreshToken: authSlice.actions.refreshToken,
  favAdsFirstShowOff: authSlice.actions.favAdsFirstShowOff,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session;
export const selectErrorMessage = (state: RootState) => state.auth.errorMessage;
export const selectedTermsAndCondition = (state: RootState) => state.auth.termsAndCondition;
export const selectedBackgroundSound = (state: RootState) => state.auth.backgroundSound;
export const selectedFavAdsFirstShow = (state: RootState) => state.auth.favAdsFirstShow;
export const selectedAuthSession = createSelector(
  (state: RootState) => state.auth.session,
  (session) => ({
    userId: session.id,
    token: session.token,
  })
);
export const selectedAuthName = createSelector(
  (state: RootState) => state.auth.session,
  (session) => ({
    firstName: session.firstName,
    lastName: session.lastName,
  })
);
export const selectedAuthToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session.token,
);
export const selectedAuthEmail = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session.emailAddress,
);
export const selectedAuthUserID= createSelector(
  (state: RootState) => state.auth.session,
  (session) => session.id,
);
export const selectedAuthCoins = createSelector(
  (state: RootState) => state.auth.session,
  (session) => getSilverGold(session.userBalanceList || []),
);
export const selectedSilverCoins = createSelector(
  (state: any) => state.auth.session,
  (state) => {
    const silver = _.find(state.userBalanceList, (item) => {
      return item.coinType.name === "SILVER";
    });

    return silver?.amount <= 100000;
  }
);
export const selectedAuthNewUser = createSelector(
  (state: any) => state.auth.session,
  (state) => {
    const userRewards = state?.userRewards ?? [];
    const newUSerReward = userRewards.find(
      (item: any) =>
        item?.rewards?.name === "New User Rewards" &&
        item.status === "CLAIMABLE"
    );

    return newUSerReward;
  }
);

export const selectedAuthProfile = createSelector(
  (state: RootState) => state.auth.session,
  (session) => ({
    firstName: session.firstName,
    lastName: session.lastName,
    country: session?.userDetail?.country?.name ?? "",
    gender: session?.userDetail?.gender?.name ?? "",
    month: session.dateOfBirth ? moment(session.dateOfBirth).format("MMMM") : "",
    day: session.dateOfBirth ? moment(session.dateOfBirth).format("D") : "",
    year: session.dateOfBirth ? moment(session.dateOfBirth).format("YYYY") : "",
  })
);

// Reducer
export default authSlice.reducer;
