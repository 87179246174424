import { AuthContext } from "@src/AuthProvider";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedSigningDataID } from "@src/ducks/slices/signup.slice";
import { images } from "@src/theme";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import { getAuth, resendEmailVerification } from "utils/api";
import useStyles from "./styles.css";

const SignupCompleted = () => {
  const styles = useStyles();
  const [loading, setLoading] = React.useState(false);
  const userId = useAppSelector(selectedSigningDataID);
  const { baseWidth } = React.useContext(DimensionContext);
  const { onSuccessMessage, onErrorMessage } = React.useContext(AuthContext);

  const onResendEmailVerification = async() => {
    try {
      setLoading(true);
      const token = await getAuth();
      const params = {
        "id": userId,
      };
      await resendEmailVerification(params, token.token);
      setLoading(false);
      onSuccessMessage("Email verification successfully sent.");
    } catch (error: any) {
      setLoading(false);
      
      if(typeof error === "string"){
        onErrorMessage(error);
      }else if(error.message){
        onErrorMessage(error.message);
      }else if(error.error && typeof error.error === "object" && error.error.code){
        onErrorMessage(error.error.code);
      }else if(error.error && typeof error.error === "object" && error.error.message){
        onErrorMessage(error.error.message);
      }else if(error.error && typeof error.error === "string"){
        onErrorMessage(error.error);
      }else{
        onErrorMessage("Something went wrong");
      }
    }
  };

  return (
    <View style={[styles.v_completed, { height: baseWidth * 0.3349}]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />

      <View style={styles.viewEmail}>
        <View style={[styles.v_congrats]}>
          <Image
            resizeMode="contain"
            source={images["text-email-verification"]}
            style={styles.forgotTextStyle}
          />
        </View>
        <Image
          style={{width: "8%", height: "10%"}}
          source={images.btn_mail}
          resizeMode="stretch"
        />
        <View style={{ height: "5%"}} />
        <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.017}]}>
        It’s nice to finally meet you! We are excited that you are {"\n"}joining us. Please check your email and verify your email {"\n"}address to continue.
        </Text>
        <View style={styles.height20} />
        <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.017}]}>
        Didn’t receive an email?
        </Text>
        <ImageButton06
          isLoading={loading}
          onPress={onResendEmailVerification}
          style={styles.btn_login}
          width={baseWidth * 0.12}
          height={baseWidth * 0.05}
          label="SEND AGAIN"
          labelStyle={{fontSize: baseWidth * 0.012}}
        />
      </View>
    </View>
  );
};

export default SignupCompleted;
