import Splash from "@src/components/splash";
import SplashBg from "components/splash/splash-bg";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableFreeze, enableScreens } from "react-native-screens";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BreakpointsProvider } from "rn-responsive-styles";
import AuthProvider from "./AuthProvider";
import DimensionProvider from "./DimensionProvider";
import store, { persistor } from "./ducks/store";
import AppNavigator from "./navigators/AppNavigator";
import CoinProvider from "./screens/home/main/CoinProvider";
import { fontAssets } from "./theme/fonts";
import { imageAssets } from "./theme/images";


enableFreeze(true);
enableScreens(true);

const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const handleLoadAssets = async () => {
    try {
      await Promise.all([...imageAssets, ...fontAssets]);
      setLoaded(true);
    } catch (e) {
      console.warn(e);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    handleLoadAssets();
  }, []);

  if (!loaded) {
    return <SplashBg />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<Splash />} persistor={persistor}>
        <BreakpointsProvider breakpoints={[1440, 1200, 850, 540]}>
          <SafeAreaProvider>
            <AuthProvider>
              <DimensionProvider>
                <CoinProvider>
                  <AppNavigator />
                </CoinProvider>
              </DimensionProvider>
            </AuthProvider>
          </SafeAreaProvider>
        </BreakpointsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
