import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_center: {
      width: "92.5%",
      height: "90%",
      marginBottom: "2%",
      marginRight: "2%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    v_center_reset: {
      width: "92.7%",
      height: "90%",
      left: "2.8%",
      top: "4%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomHeight: {
      width: 100,
      height: "18%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto_Medium",
    },

    // ENTER EMAIL COMPONENT STYLE
    viewForgotStyle: {
      width: "70%",
      height: "21%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: 60 },
    viewEmail: {
      flex: 1,
      right: "1%",
      width: "93%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto_Regular",
    },
    inputEmailStyle: { marginTop: 30, height: "15%" },
    height20: { height: 20 },
    btnSubmit: { height: "15%", width: "20%", marginLeft: 10 },
    btnClose: {
      position: "absolute",
      zIndex: 1,
      top: "20%",
      right: "-35%",
    },
    imageCloseStyle: { height: 30, width: 30 },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },

    // RESET PASSWORD
    ti_reset: { marginTop: 10, height: 50, marginBottom: 10 },
    btn_reset_password: { height: "16%", width: "30%", marginLeft: 10 },

    // SUCCESS
    i_success: { width: "50%", height: "24%" },
    v_logo: {
      width: "18%",
      height: "33%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_fortune_logo: {
      width: "100%",
      height: "100%",
    },
  },
);

export default useStyles;
