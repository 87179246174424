import * as Font from "expo-font";

// fonts preloading
export const fontAssets = [
  {
    Roboto_Regular: require("../../assets/fonts/Roboto-Regular.ttf"),
  },
  {
    Roboto_Light: require("../../assets/fonts/Roboto-Light.ttf"),
  },
  {
    Roboto_Medium: require("../../assets/fonts/Roboto-Medium.ttf"),
  },
  {
    Roboto_Bold: require("../../assets/fonts/Roboto-Bold.ttf"),
  },
  {
    Risque_Regular: require("../../assets/fonts/Risque-Regular.ttf"),
  },
  {
    Bebas_Regular: require("../../assets/fonts/BebasNeue-Regular.ttf"),
  },
  {
    Lalezar_Regular: require("../../assets/fonts/Lalezar-Regular.ttf"),
  },
  {
    ABeeZee_Regular: require("../../assets/fonts/ABeeZee-Regular.ttf"),
  },
].map((x: any) => Font.loadAsync(x));
