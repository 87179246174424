import { AUTH_PASSWORD, AUTH_USERNAME } from "@env";
import * as Types from "@src/ducks/types";
import * as api from "./request";

export const getAuth = () =>
  api.callPost("/authenticate", {
    username: AUTH_USERNAME,
    password: AUTH_PASSWORD,
  });

export const checkingAuthentication = (params: Types.SessionValue) => {
  return new Promise((resolve) => {
    api.callGet(`/api/v1/user/${params.userId}/wallet`, params.token).then(() => {
      resolve(params.token);
    }).catch(async (e) => {
      console.log("checkingAuthentication", e);
      if(e.status === 401 && e.error === "Unauthorized"){
        api.callPost("/authenticate", {
          username: AUTH_USERNAME,
          password: AUTH_PASSWORD,
        }).then((token) => {
          resolve(token.token);
        });
      }else{
        resolve(params.token);
      }
    });
  });
};
  
export const login = (params: Types.LoginInput, token: string) =>
  api.callPost("/api/v1/user/login", { "partnerId": 1, ...params}, token);

export const googleAuth = (params: any) =>
  api.get("https://www.googleapis.com/userinfo/v2/me", params);

export const loginWithGoogle = (params: any, token: string) =>
  api.callPost("/api/v1/user?type=google&partnerId=1", params, token);

export const facebookAuth = (accessToken: string) =>
  api.get(`https://graph.facebook.com/me?fields=email,first_name,last_name,gender&access_token=${accessToken}`, null);

export const loginWithFacebook = (params: any, token: string) =>
  api.callPost("/api/v1/user?type=fb&partnerId=1", params, token);

export const register = (params: Types.SignupInput) =>
  api.callPost("/api/v1/user?type=1&partnerId=1", params);

export const resendEmailVerification = (params: any, token: string) =>
  api.callPost(`/api/v1/user/${params.id}/verification/resend-email`, {}, token);

export const accountVerify = (params: any, token: string) =>
  api.callPost("/api/v1/user/verify", params, token);

export const forgotpassword = (email: string, token: string) =>
  api.callPost(`/api/v1/user/mail?email=${email}&deviceType=web`,{}, token);

export const resetpassword = (params: Types.ResetPasswordInput, token: string) =>
  api.callPost(`/api/v1/user/password?password=${params.password}&email=${params.email}&reset_password=${params.otp}`, params, token);

// LOBBY CALL
export const walletBalance = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/user/${params.userId}/wallet`, params.token);

export const jadeEggAccumulation = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/user/${params.userId}/jade`, params.token);

export const fetchUserRank = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/leaderboard/score/${params.userId}`, params.token);

export const fetchRandomRewards = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/rewards/${params.userId}?rewardsId=2`, params.token);

export const fetchMissions = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/user/${params.userId}/mission`, params.token);

export const fetchAvatarTier = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/evo/avatartier/${params.userId}`, params.token);

export const fetchGames = (params: Types.SessionValue) =>
  api.callGet( `/api/v1/game/list/0?tierID=4&userID=${params.userId}&isActive=1`, params.token);

export const fetchProducts = (params: Types.SessionValue) =>
  api.callGet( "/api/v1/products/get", params.token);

export const fetchTierUpgradeBonuses = (params: Types.SessionValue) =>
  api.callGet( `/api/v1/user/${params.userId}/tier-bonuses`, params.token);

export const claimTierUpgradeBonus = (params: Types.SessionValue) =>
  api.callPost( `/api/v1/user/${params.userId}/tier-bonuses/claim`, params, params.token);

export const checkDailyReward = async (params: Types.SessionValue) => {
  const result = await api.callGet(`/api/v1/rewards/${params.userId}/isClaimed`, params.token);

  if(result.data) {
    const server = await api.callGet("/utils/server", params.token);
    return server.data;
  } else{
    return 0;
  }
};

export const checkWeeklyMissions = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/rewards/${params.userId}/isClaimed?rewardID=12`, params.token);

export const check3hoursReward = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/user/${params.userId}/checkcollect`, params.token);

export const fetchNotifications = (params: Types.SessionValue) =>
  api.callGet(`/api/v1/${params.userId}/notifications`, params.token);

export const collectDailyReward = (params: Types.DailyReward) =>
  api.callPost(`/api/v1/rewards/${params.userId}?rewardName=${params.rewardName}`, {}, params.token);

export const collect3HoursRewards = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/user/${params.userId}/collect`, {}, params.token);

export const collectSigningBonus = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/rewards?userRewardsId=${params.userRewardsId}`, {}, params.token);

export const echangeGoldToSilver = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/user/${params.userId}/exchange/goldcoin`, {}, params.token);

export const collectMissionReward = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/rewards/${params.userId}/mission/rewards`, {}, params.token);

// SETTINGS
export const deactivateAccount = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/user/status?id=${params.userId}&status=DEACTIVATE`, {}, params.token);

export const changeAvatar = (params: Types.ChangeAvatar, token: string) =>
  api.callPost("/api/v1/evo/avatar", params, token);

// GAME MODE
export const fetchGameURL = (params: Types.GameMode) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&gameTypeID=${params.gameTypeID}`, params.token);

export const fetchGameURLCurrency = (params: Types.GameMode) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&gameTypeID=${params.gameTypeID}&currency=${params.currency}`, params.token);

export const fetchGameURLSwordPlay = (params: Types.GameMode) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&platform=pc&timestamp=${params.timestamp}&gameTypeID=${params.gameTypeID}`, params.token);

export const missionCompleted = (params: Types.GameMode) =>
  api.callPost(`/api/v1/mission/${params.missionId}/status?gameCategoryID=${params.gameCategoryID}&userId=${params.userId}`, {}, params.token);
  
export const swordplayPaymentInit = (params: Types.SwordPlayInit, token: string) =>
  api.callPost("/api/v1/icasino/synapps/payment_init", params, token);

export const swordplayPaymentCallback = (params: Types.SwordPlayCB, token: string) =>
  api.callPost("/api/v1/icasino/synapps/payment_callback", params, token);

// PURCHASE AT BUYSHOP
export const purchaseItem = (params: Types.Transaction) =>
  api.callPost("/api/v1/payment/transaction", params, params.token);

export const recordPurchase = (params: Types.Transaction) =>
  api.callPost(`/api/v1/user/${params.userId}/wallet?productName=${params.purchaseItem}&paymentRefNo=${params.paymentRefNo}`, {}, params.token);

export const recordPurchaseSubscription = (params: Types.Subscription) =>
  api.callPost("/api/v1/subscription", params, params.token);

export const recordPurchaseJade = (params: Types.SessionValue) =>
  api.callPost(`/api/v1/jade/${params.userId}`, {}, params.token);

export const apcopayCreditCard = (params: Types.Subscription) =>
  api.callPost("/api/v1/apcopay/get_checkout_url", params, params.token);


// BITPACE API CALL
export const bitpaceMerchantAuth = () => api.merchantAuth();

export const bitpaceMerchantCreateOrder = (params: any, token: string) =>
  api.merchantPost("/fixed-deposit/url", params, token);


// FETCH FAVORITE GAMES
export const fetchMyFavoriteGames = (userId: any, token: string) =>
  api.callGet("/api/v1/user/getUserFavouriteGames?userId=" + userId, token);

export const createMyFavoriteGames = (params: any, token: string) =>
  api.callPost("/api/v1/user/createUserFavouriteGames", params, token);

export const deleteMyFavoriteGames = (params: any, token: string) =>
  api.callPost("/api/v1/user/deleteUserFavouriteGames", params, token);

