import type {
  StackNavigationOptions,
  StackNavigationProp,
} from "@react-navigation/stack";

export type RootStackParamList = {
  "SignInOption": undefined;
  SignIn: undefined;
  SignUp: undefined;
  Forgot: undefined;
  ResetPassword: undefined;
  AccountVerification: undefined;
  "privacy-policy": undefined;
  "ErrorPopup": { message: string };

  Home: undefined;
  WelcomeBonus: undefined;
  DailyBonus: undefined;
  EditName: undefined;
  EditProfile: undefined;
  TierLevel: undefined;
  Disclaimer: undefined;
  LeaderBoardInfo: undefined;
  BuyShop: undefined;
  ExchangeGold: undefined;
  JadeEgg: undefined;
  JadeEggShow: undefined;
  Mission: undefined;
  MissionKey: undefined;
  MissionReward: undefined;
  Inbox: undefined;
  PaymentGateway: undefined;
  PaymentConfirm: undefined;
  BitpaceResult: undefined;
  StarterPack: undefined;
  OpeningSpecial: undefined;
  GameMode: any;
  Settings: undefined;
  Apcopay: undefined;

  DragonRoller: undefined;
  HighRoller: undefined;
  HotDeals: undefined;
  TGIF: undefined;
  WeeklyBooster: undefined;
  HotDealsThumbnails: undefined;
  TierRewards: undefined;
  TierLevelUp: undefined;
  GoldCoinEarning: undefined;
  PremiumPackages: undefined;
};

export type DrawerStackParamList = {
  Dashboard: undefined;
  Account: undefined;
  Transactions: undefined;
  Signup: undefined;
};

export type TabStackParamList = {
  Home: undefined;
  Account: undefined;
  Settings: undefined;
  Signup: undefined;
};

export type NavigationScreenProps<K extends keyof RootStackParamList> =
  StackNavigationProp<RootStackParamList, K>;

export interface RootStackOptionsValue extends StackNavigationOptions {
  cardStyleInterpolator?: any;
}

export interface ForSlideProps {
  current: any;
  next: any;
  inverted: any;
  layouts: any;
}

const FadeInterpolator = ({ current }: ForSlideProps) => {
  return {
    cardStyle: {
      opacity: current.progres,
    },
  };
};

export const TransitionModal: RootStackOptionsValue = {
  headerShown: false,
  gestureEnabled: false,
  cardShadowEnabled: false,
  cardOverlayEnabled: false,
  presentation: "transparentModal",
  cardStyleInterpolator: FadeInterpolator,
};
