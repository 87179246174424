import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { verticalScale } from "@src/utils/metrics";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "30%",
      height: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_logo: { width: verticalScale(200), height: verticalScale(200) },
    t_loading: {
      fontSize: 16,
      fontWeight: "500",
      color: colors.white,
      marginVertical: "1%",
    },
    v_progress: {
      height: "5%",
      width: "30%",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    i_gameworkz: {
      width: "20%",
      height: "11%",
      position: "absolute",
      left: "6%",
      bottom: "10%",
    },
    i_progress_bg: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "absolute",
    },
    v_maskprogress: {
      width: "98%",
      height: "80%",
      borderRadius: 100,
    },
    v_mask: {
      left: 0,
      width: "1%",
      height: "80%",
      position: "absolute",
      backgroundColor: colors.progress,
    },
    t_version: {
      bottom: "3%",
      fontSize: 10,
      color: "white",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      bodyStyle: {
        width: "60%",
      },
    },
    [DEVICE_SIZES.SM]: {
      bodyStyle: {
        width: "60%",
      },
      v_progress: { width: "60%" },
      i_logo: { width: verticalScale(200), height: verticalScale(200) },
      i_gameworkz: { width: verticalScale(120), height: verticalScale(80) },
    },
    [DEVICE_SIZES.XS]: {
      bodyStyle: {
        width: "60%",
      },
      v_progress: { width: "60%" },
      i_logo: { width: verticalScale(170), height: verticalScale(170) },
      i_gameworkz: { width: verticalScale(100), height: verticalScale(70) },
    },
  }
);

export default useStyles;
