import React from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import {Button, ImageButton02 } from "components/button";
import { View, Image, Text } from "react-native";
import SVGIcon from "@assets/svg";
import SVGSettings from "@assets/svg/settings";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const Disclaimer = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container}>
      <View style={[styles.bodyStyle, 
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={images.bg_transparent}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <ImageButton02
          onPress={() => navigation.goBack()}
          style={styles.btn_close}
        >
          <SVGIcon name="close" width={baseWidth * 0.04} height={baseWidth * 0.09} />
        </ImageButton02>
        <View style={styles.v_center}>
          <View style={styles.viewForgotStyle}>
            <SVGSettings name="t-disclaimer" width={baseWidth * 0.2} height={baseWidth * 0.04} />
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, { fontSize: baseWidth * 0.018}]}>
            Welcome to Fortune8. Please enjoy our range of new social games available online. You must be 18+ years and older and be aware of the following.{"\n\n"}
            The  games contained in Fortune8 does not offer gambling and in no way offer any real money wins, real prizes or equivalent. Fortune8 is strictly a social gaming application and our game mechanics do not promise success in any gambling in the future. 
            </Text>
          </View>
          <Button
            onPress={() => navigation.goBack()}
            style={styles.btnSubmit}
            width={baseWidth * 0.12}
            height={baseWidth * 0.05}
            label="CLOSE"
            labelStyle={{fontSize: baseWidth * 0.012}}
          />
        </View>
      </View>
    </View>
  );
};

export default Disclaimer;
