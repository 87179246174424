type PromotionPopups = {
    STARTER_PACK: string;
    CHEAPY_TUESDAY: string;
    WEEKLY_BOOSTER: string;
    HIGH_ROLLER: string;
    TGIF: string;
    DRAGON_ROLLER: string;
    PACKAGE_1: string;
    PACKAGE_2: string;
    PREMIUM_PACK1: string;
    PREMIUM_PACK2: string;
    PREMIUM_PACK3: string;
}

export const PROMOTION_POPUPS: PromotionPopups = {
  STARTER_PACK: "gwz_3.00_3m_silv",
  CHEAPY_TUESDAY: "gwz_4.00_4m_silv",
  WEEKLY_BOOSTER: "gwz_7.00_8m_silv",
  HIGH_ROLLER: "gwz_11.00_13m_silv",
  TGIF: "gwz_7.00_7m_silv",
  DRAGON_ROLLER: "gwz_20.00_25m_silv",
  PACKAGE_1: "gwz_2.99_2m_silv",
  PACKAGE_2: "gwz_5.99_6m_silv",
  PREMIUM_PACK1: "gwz_1.99_1m_silv",
  PREMIUM_PACK2: "gwz_2.99_4.4m_silv",
  PREMIUM_PACK3: "gwz_4.99_10.5m_silv",
};