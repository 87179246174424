import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useState } from "react";
import { CurrentApi } from "../request";

type FetchPostValue = {
  loading: boolean;
  data: any;
  error: any;
  runRequest: (routes: string, params: any) => void;
  runReset: () => void;
}

export const useFetchPost = (initialData = null) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState(initialData);
  const token: any = useAppSelector(selectedAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const runRequest = useCallback(
    async (routes: string, params: any) => {
      try {
        setLoading(true);
        CurrentApi.setToken(token);
        const result = await CurrentApi.post(`${routes}`, params);

        setData(result.data || true);
      } catch (err: any) {

        console.log("err", err);
        if(err?.status === 401 && err?.error === "Unauthorized"){
          dispatch(authActions.logout());
          dispatch(authActions.setErrorMessage("Session Expired"));
        }

        if(typeof err === "string"){
          setError({ message: err });
        }else if(err.message){
          setError({ message: err.message });
        }else if(err.error && typeof err.error === "object"){
          setError({ message: err.error.message });
        }else if(err.error && typeof err.error === "string"){
          setError({ message: err.error });
        }else{
          setError({ message: "Something went wrong" });
        }
      } finally {
        setLoading(false);
      }
    },
    [token, dispatch]
  );

  const runReset = useCallback(() => {
    setLoading(false);
    setData(null);
    setError(null);
  },[data, error]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
  } as FetchPostValue;
};
