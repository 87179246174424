import { createAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";

import { ErrorValue, SessionValue, TierBonus } from "../types";

export interface InitialState {
    tierUpgradeBonuses: TierBonus[];
    isClaiming: boolean;
    error: ErrorValue;
    unlockedTierLevels: boolean;
}

export const initialState: InitialState = {
  tierUpgradeBonuses: [],
  isClaiming: false,
  error: {},
  unlockedTierLevels: false,
};

export const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {
    tierUpgradeBonuses: (state, action) => {
      state.tierUpgradeBonuses = action.payload;
    },
    claimTierUpgradeBonusRequest: (state) => {
      state.isClaiming = true;
    },
    claimTierUpgradeBonusSuccess: (state) => {
      state.isClaiming = false;
    },
    claimTierUpgradeBonusFailed: (state, action) => {
      state.isClaiming = false;
      state.error = action.payload;
    },
    setUnlockedTierLevels: (state, action) => {
      state.unlockedTierLevels = action.payload;
    }
  }
});

export const tierActions = {
  tierUpgradeBonuses: tierSlice.actions.tierUpgradeBonuses,
  claimTierUpgradeBonusRequest: createAction(
    `${tierSlice.name}/claimTierUpgradeBonusRequest`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  claimTierUpgradeBonusSuccess: tierSlice.actions.claimTierUpgradeBonusSuccess,
  claimTierUpgradeBonusFailed: tierSlice.actions.claimTierUpgradeBonusFailed,
  setUnlockedTierLevels: tierSlice.actions.setUnlockedTierLevels,
};

export const selectedTierUpgradeBonuses = (state: RootState) => state.tier.tierUpgradeBonuses;
export const selectedUnlockedTierLevels = (state: RootState) => state.tier.unlockedTierLevels;

export default tierSlice.reducer;