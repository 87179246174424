import { SagaIterator } from "@redux-saga/core";
import { call, put, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  deactivateAccount,
  facebookAuth,
  forgotpassword,
  getAuth,
  googleAuth,
  login,
  loginWithFacebook,
  loginWithGoogle,
  register,
  resetpassword,
} from "@src/utils/api";

// Slice
import { authActions } from "../slices/auth.slice";
import { forgotActions } from "../slices/forgot.slice";
import { lobbyActions } from "../slices/lobby.slice";
import { settingsActions } from "../slices/settings.slice";
import { signupActions } from "../slices/signup.slice";

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const session = yield call(login, action.payload, token.token);

    if (session.data.isVerified === false) {
      throw { code: 401, message:  "Unverified account. Please check your email and verify your email address to continue.", };
    }

    if (session.data.isDeleted) {
      throw { code: 401, message:  "Your account is disabled. Please contact customer support", };
    }

    yield put(authActions.loginSuccess({...session.data, token: token.token}));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error?.error?.message || "Something went wrong";

    yield put(authActions.loginFailure(error?.error ?? error));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleSignInWithGoogle(action: {
  type: typeof authActions.loginRequest;
  payload: string;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const payload = {
      headers: {
        Authorization: "Bearer " + action.payload,
      },
    };
    const google = yield call(googleAuth, payload);
    const params = {
      firstName: google.given_name,
      lastName: google.family_name,
      password: google.id,
      emailAddress: google.email,
      apiKey: "test",
      changeOnNextLogon: 0,
      userTypeID: 1,
      googleID: google.id,
    };

    const session = yield call(loginWithGoogle, params, token.token);

    if (session.data.isDeleted) {
      throw { code: 401, message:  "Your account is disabled. Please contact customer support", };
    }

    yield put(authActions.loginSuccess({...session.data, token: token.token}));
  } catch (error: any) {
    const message = error.message || error?.error?.message || "Something went wrong";

    yield put(authActions.loginFailure(error?.error ?? error));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleSignInWithFacebook(action: {
  type: typeof authActions.loginRequest;
  payload: string;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const facebook = yield call(facebookAuth, action.payload);
    const params = {
      firstName: facebook.first_name,
      lastName: facebook.last_name,
      password: facebook.id,
      emailAddress: facebook.email,
      apiKey: "test",
      changeOnNextLogon: 0,
      userTypeID: 1,
      facebookID: facebook.id,
    };

    const session = yield call(loginWithFacebook, params, token.token);

    if (session.data.isDeleted) {
      throw { code: 401, message:  "Your account is disabled. Please contact customer support", };
    }

    yield put(authActions.loginSuccess({...session.data, token: token.token}));
  } catch (error: any) {
    const message = error.message || error?.error?.message || "Something went wrong";

    yield put(authActions.loginFailure(error?.error ?? error));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleSignup(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    const result = yield call(register, action.payload);
    yield put(signupActions.signupSuccess(result.data));
  } catch (error: any) {
    let message = error?.error?.message ?? "Something went wrong";
    message = message.replace("email already used.", "This email is already registered.");

    yield put(signupActions.signupFailure(message));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleForgotpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: string;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const result = yield call(forgotpassword, action.payload, token.token);

    yield put(forgotActions.forgotSuccess({...result.data, email: action.payload}));
  } catch (error: any) {
    let message = error?.error?.message ?? "Something went wrong";
    message = message.replace("Invalid User/Email or Password", "Invalid email address");
    yield put(forgotActions.forgotFailed(message));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleResetpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.ResetPasswordInput;
}): SagaIterator {
  try {
    const token = yield call(getAuth);
    const user = yield call(resetpassword, action.payload, token.token);

    yield put(forgotActions.resetSuccess(user.data));
  } catch (error: any) {
    const message = error?.error?.message ?? "Something went wrong";

    yield put(forgotActions.resetFailed({ message: message }));
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleDeactivateRequest(action: {
  type: typeof settingsActions.deactivateAccount;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    yield call(deactivateAccount, action.payload);
    yield put(authActions.logout());
  } catch (error: any) {
    const message = error?.error?.message ?? "Something went wrong";
    yield put(authActions.setErrorMessage(message));
  }
}

function* handleLogout(): SagaIterator {
  yield put(settingsActions.hasSettingsOpen(false));
  yield put(lobbyActions.lobbyReset());
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.loginGoogleRequest.type, handleSignInWithGoogle);
  yield takeEvery(authActions.loginFBRequest.type, handleSignInWithFacebook);
  yield takeEvery(signupActions.signupRequest.type, handleSignup);
  yield takeEvery(forgotActions.forgotRequest.type, handleForgotpassword);
  yield takeEvery(forgotActions.resetRequest.type, handleResetpassword);
  yield takeEvery(authActions.logout.type, handleLogout);
  yield takeEvery(settingsActions.deactivateAccount.type, handleDeactivateRequest);
}

export default authWatcherSaga;
