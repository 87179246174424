import React, { useEffect } from "react";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

const OpacityAnimated = ({
  children,
  onLayout,
  style,
  duration,
  toValue,
  pointerEvents,
}: any) => {
  const animated = useSharedValue(0);
  const animateStyle = useAnimatedStyle(() => {
    return {
      display: animated.value === 1 ? "flex" : "none",
    };
  }, []);

  useEffect(() => {
    animated.value = withTiming(toValue, { duration: duration || 200 });
  }, [toValue, duration]);

  return (
    <Animated.View
      pointerEvents={pointerEvents}
      onLayout={onLayout}
      style={[
        style,
        animateStyle,
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default OpacityAnimated;
